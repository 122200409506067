import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78')
];

export const server_loads = [0,2,3];

export const dictionary = {
		"/": [~13],
		"/about": [14],
		"/blog": [15],
		"/blog/[slug=slug]": [16],
		"/community": [17],
		"/dao": [18],
		"/diagnostics": [~19],
		"/glossary": [20,[2]],
		"/glossary/[slug=slug]": [21,[2]],
		"/newsletter": [22],
		"/qr": [23],
		"/search": [24],
		"/sentry-test": [~25],
		"/slow-load": [26],
		"/strategies": [~27,[3]],
		"/strategies/[strategy]": [28,[3,4]],
		"/strategies/[strategy]/backtest": [32,[3,4]],
		"/strategies/[strategy]/description": [33,[3,4]],
		"/strategies/[strategy]/fees": [34,[3,4]],
		"/strategies/[strategy]/netflow": [35,[3,4]],
		"/strategies/[strategy]/performance": [36,[3,4]],
		"/strategies/[strategy]/source": [37,[3,4]],
		"/strategies/[strategy]/tech-details": [38,[3,4,7]],
		"/strategies/[strategy]/tech-details/decision-making": [39,[3,4,7]],
		"/strategies/[strategy]/tech-details/logs": [40,[3,4,7]],
		"/strategies/[strategy]/tech-details/status": [41,[3,4,7]],
		"/strategies/[strategy]/vault": [42,[3,4]],
		"/strategies/[strategy]/[status=positionStatus]-positions": [29,[3,4,5]],
		"/strategies/[strategy]/[status=positionStatus]-positions/[position=integer]": [30,[3,4,5,6]],
		"/strategies/[strategy]/[status=positionStatus]-positions/[position=integer]/trade-[trade=integer]": [31,[3,4,5,6]],
		"/trading-view": [43],
		"/trading-view/api": [56],
		"/trading-view/backtesting": [57],
		"/trading-view/blockchains": [58],
		"/trading-view/exchanges": [59],
		"/trading-view/lending-reserves": [60],
		"/trading-view/top-list": [61],
		"/trading-view/top-list/[direction=momentum]": [62],
		"/trading-view/trading-pairs": [63],
		"/trading-view/[chain=slug]": [44,[8]],
		"/trading-view/[chain=slug]/exchanges": [49,[8]],
		"/trading-view/[chain=slug]/lending": [50,[8]],
		"/trading-view/[chain=slug]/lending/[protocol]": [51,[8]],
		"/trading-view/[chain=slug]/lending/[protocol]/[reserve]": [52,[8]],
		"/trading-view/[chain=slug]/tokens": [53,[8]],
		"/trading-view/[chain=slug]/tokens/[token]": [54,[8]],
		"/trading-view/[chain=slug]/trading-pairs": [55,[8]],
		"/trading-view/[chain=slug]/[exchange]": [45,[8]],
		"/trading-view/[chain=slug]/[exchange]/export-data": [48,[8]],
		"/trading-view/[chain=slug]/[exchange]/[pair]": [46,[8]],
		"/trading-view/[chain=slug]/[exchange]/[pair]/api-and-historical-data": [47,[8]],
		"/wizard/connect-wallet/balance": [64,[9,10]],
		"/wizard/connect-wallet/connect": [65,[9,10]],
		"/wizard/connect-wallet/introduction": [66,[9,10]],
		"/wizard/connect-wallet/success": [67,[9,10]],
		"/wizard/deposit/balance": [68,[9,11]],
		"/wizard/deposit/connect": [69,[9,11]],
		"/wizard/deposit/introduction": [70,[9,11]],
		"/wizard/deposit/payment": [71,[9,11]],
		"/wizard/deposit/success": [72,[9,11]],
		"/wizard/deposit/tos": [73,[9,11]],
		"/wizard/redeem/connect": [74,[9,12]],
		"/wizard/redeem/deposit-status": [75,[9,12]],
		"/wizard/redeem/introduction": [76,[9,12]],
		"/wizard/redeem/shares-redemption": [77,[9,12]],
		"/wizard/redeem/success": [78,[9,12]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';